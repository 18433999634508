const modifyGrid = (data) => {
  const order = [
    '/retouch',
    '/nature-photography',
    '/product-photography',
    '/food-photography',
    '/portraits',
    '/digital-art',
    '/about',
  ];
  const sorted = data.sort((a, b) => {
    return order.indexOf(a.slug) - order.indexOf(b.slug);
  });

  return sorted;
};

export default modifyGrid;
